import React, { useState } from 'react'

import MinisRow from './MinisRow'

export default function MinisList( { minis } ) {

	const [ rowSelected, setRowSelected ] = useState()

	const minisGrouper = chunkArray( minis, 4 )

	function chunkArray( array, size ) {
		let result = []
		let arrayCopy = [ ...array ]

		while ( arrayCopy.length > 0 ) {
			result.push( arrayCopy.splice( 0, size ) )
		}

		return result
	}

	return (
		<section className="wrapper">
			{ minisGrouper.map( ( group, i ) => (
				<MinisRow
					key={ i }
					minis={ group }
					rowIndex={ i }
					rowSelected={ rowSelected }
					setRowSelected={ setRowSelected } />
			) ) }
		</section>
	)
}