import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'

import PageHeader from '../components/PageHeader/PageHeader'
import MinisList from '../components/Minis/MinisList'

const sculptures = ( { data: { strapi: {  minis } }, location } ) => {

	const trailYear = minis.length ? minis[ 0 ].artTrail.trailYear : ''

	return (
		<>
			<SEO title={ `Minis ${ trailYear }` } pathname={ location.pathname } />

			<PageHeader heading="Minis" />

			<MinisList minis={ minis } />
		</>
	)
}

export default sculptures

export const query = graphql`
	query minisQuery($id: ID!) {
		strapi {
			minis( where: { artTrail: $id }, sort: "miniName:ASC" ) {
				id
				miniName
				miniImage {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
				miniArtistName
				miniArtistWebsite
				miniArtistImage {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000, maxHeight: 1000) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
				miniArtistSocial {
					id
					social
					socialLink
				}
				miniSponsorName
				miniSponsorWebsite
				miniSponsorImage {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxHeight: 1000) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
				miniSponsorSocial {
					id
					social
					socialLink
				}
				artTrail {
					trailYear
				}
			}
		}
	}
`