import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'

import IconFacebook from '../../images/svgs/facebook-icon.svg'
import IconInstagram from '../../images/svgs/instagram-icon.svg'
import IconLinkedin from '../../images/svgs/linkedin-icon.svg'
import IconTwitter from '../../images/svgs/twitter-icon.svg'
import IconWebsite from '../../images/svgs/website-icon.svg'
import IconPlus from '../../images/svgs/plus-icon.svg'

export default function MinisRow( props ) {

	const { minis, rowIndex, rowSelected, setRowSelected } = props
	const [ activeMini, setActiveMini ] = useState()

	useEffect(() => {
		if ( rowSelected !== rowIndex ) {
			setActiveMini()
		}
	}, [ rowIndex, rowSelected ] )

	const handleClick = ( member ) => {

		if ( activeMini && activeMini.id === member.id ) {
			setActiveMini()
			setRowSelected()
		} else {
			setActiveMini( member )

			// add quick delay before scrolling down to allow render 1/10th second
			setTimeout(() => {
				const selectedContainer = document.getElementById( `#mini-${ rowIndex }` )
				selectedContainer.scrollIntoView({ behavior: 'smooth', block: 'end' })
			}, 100)

			setRowSelected( rowIndex )
		}
	}

	return (
		<div className="minis__container">

			<div className="minis__group-container">
				{ minis.map( mini => (
					<button className={`minis__member ${ activeMini && activeMini.id === mini.id ? 'active' : '' }`} key={ mini.id } onClick={ () => handleClick( mini ) }>
						<div className="minis__image-container">
							<Img fluid={{ ...mini.miniImage[ 0 ].imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="minis__image" />
						</div>
						<div className="minis__name">
							{ mini.miniName }
							<IconPlus className="minis__name-icon" />
						</div>
					</button>
				) ) }
			</div>

			{ activeMini &&
				<div className="minis__selected-container" id={ `#mini-${ rowIndex }` }>
					<div className="minis__selected-image-container">
						<Img fluid={{ ...activeMini.miniImage[ 0 ].imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="minis__selected-image" />
					</div>
					<div className="minis__meta-container">
						<div className="minis__meta-name">{ activeMini.miniName }</div>
						<div className="minis__sponsors">
							<div className="minis__sponsor">
								<div className="minis__sponsor-label">Sponsored by:</div>
								{ activeMini.miniSponsorName && <div className="minis__sponsor-name">{ activeMini.miniSponsorName }</div> }
								{ activeMini.miniSponsorImage &&
									<div className="minis__sponsor-image-container">
										<Img className="minis__sponsor-image" fluid={{ ...activeMini.miniSponsorImage.imageFile.childImageSharp.fluid }} imgStyle={{ objectFit: "contain" }} />
									</div>
								}
								<div className="minis__sponsor-socials">
									{ activeMini.miniSponsorWebsite && <a href={ activeMini.miniSponsorWebsite } className="minis__sponsor-link" target="_blank" rel="noreferrer"><IconWebsite className="minis__sponsor-icon" /> </a>}
									{ activeMini.miniSponsorSocial &&
										<>
											{ activeMini.miniSponsorSocial.map( social => (
												<a href={ social.socialLink } className="minis__sponsor-link" target="_blank" rel="noreferrer" key={ social.id }>
													{ social.social === "Instagram" && <IconInstagram className="minis__sponsor-icon" />}
													{ social.social === "Facebook" && <IconFacebook className="minis__sponsor-icon" />}
													{ social.social === "LinkedIn" && <IconLinkedin className="minis__sponsor-icon" />}
													{ social.social === "Twitter" && <IconTwitter className="minis__sponsor-icon" />}
												</a>
											) ) }
										</>
									}
								</div>
							</div>
							<div className="minis__sponsor">
								<div className="minis__sponsor-label">Artist:</div>
								{ activeMini.miniArtistName && <div className="minis__sponsor-name">{ activeMini.miniArtistName }</div> }
								{ activeMini.miniArtistImage &&
									<div className="minis__sponsor-image-container">
										<Img className="minis__sponsor-image minis__sponsor-image--artist" fluid={{ ...activeMini.miniArtistImage.imageFile.childImageSharp.fluid }} imgStyle={{ objectFit: "contain" }} />
									</div>
									}
								<div className="minis__sponsor-socials">
									{ activeMini.miniArtistWebsite && <a href={ activeMini.miniArtistWebsite } className="minis__sponsor-link" target="_blank" rel="noreferrer"><IconWebsite className="minis__sponsor-icon" /> </a>}
									{ activeMini.miniArtistSocial &&
										<>
											{ activeMini.miniArtistSocial.map( social => (
												<a href={ social.socialLink } className="minis__sponsor-link" target="_blank" rel="noreferrer" key={ social.id }>
													{ social.social === "Instagram" && <IconInstagram className="minis__sponsor-icon" />}
													{ social.social === "Facebook" && <IconFacebook className="minis__sponsor-icon" />}
													{ social.social === "LinkedIn" && <IconLinkedin className="minis__sponsor-icon" />}
													{ social.social === "Twitter" && <IconTwitter className="minis__sponsor-icon" />}
												</a>
											) ) }
										</>
									}
								</div>
							</div>
						</div>
						<IconPlus className="minis__name-icon minis__name-icon--close" onClick={ () => handleClick( activeMini )} />
					</div>
				</div>
			}
		</div>
	)
}